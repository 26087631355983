@use "@angular/material" as mat;

:root {
  --primary-color: #{$primary-color};
  --second-color: #{$second-color};
  --third-color: #{$third-color};
  // * for RGBA
  --rgb-primary-color: #{$rgb-primary-color}; //--primary-color *
  --rgb-second-color: #{$rgb-second-color}; //--second-color *
  --rgb-third-color: #{$rgb-third-color}; //--third-color *
}

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: rgba(white, 0.87);

$primary-palette: (
  50: lighten($primary-color, 13%),
  100: lighten($primary-color, 12%),
  200: lighten($primary-color, 11%),
  300: lighten($primary-color, 10%),
  400: lighten($primary-color, 9%),
  500: lighten($primary-color, 8%),
  600: lighten($primary-color, 7%),
  700: lighten($primary-color, 6%),
  800: lighten($primary-color, 5%),
  900: $primary-color,
  A100: lighten($primary-color, 14%),
  A200: lighten($primary-color, 10%),
  A400: lighten($primary-color, 2%),
  A700: darken($primary-color, 2%),
  // * Color oscuro
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$second-palette: (
  50: lighten($second-color, 13%),
  100: lighten($second-color, 12%),
  200: lighten($second-color, 11%),
  300: lighten($second-color, 10%),
  400: lighten($second-color, 9%),
  500: lighten($second-color, 8%),
  600: lighten($second-color, 7%),
  700: lighten($second-color, 6%),
  800: lighten($second-color, 5%),
  900: $second-color,
  A100: lighten($second-color, 14%),
  A200: lighten($second-color, 10%),
  A400: lighten($second-color, 2%),
  A700: darken($second-color, 2%),
  // * Color oscuro
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$ng-project-primary: mat.define-palette($primary-palette);
$ng-project-accent: mat.define-palette($second-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ng-project-warn: mat.define-palette(mat.$red-palette);

$ng-project-theme: mat.define-light-theme(
  (
    color: (
      primary: $ng-project-primary,
      accent: $ng-project-accent,
      warn: $ng-project-warn,
    ),
  )
);

@include mat.all-component-themes($ng-project-theme);